<template>
  <div>
    <b-card class="bg-secondary rounded-0">
      <b-card-img :src="gameData.icon" :alt="gameData.name"
        style="border: 3px solid #fff;box-shadow: 0 0 1px rgba(0,0,0,0.3); width:150px; height:150px;"></b-card-img>
      <b-card-body class="px-0" style="padding-bottom: 0;">
        <b-card-title title-tag="h4" style="color:#fff;font-weight: 800;text-transform: uppercase;">{{
        gameData.name
      }}</b-card-title>
        <b-card-text style="color: rgb(210 210 210); font-weight:500;">
          <b-icon icon="joystick" font-scale="1" variant="primary"></b-icon>
          {{ kFormatter(gameData.playNum) }} played
        </b-card-text>
        <!-- <b-button href="#" variant="primary">Play</b-button> -->
        <b-button variant="primary" style="width:250px;" @click="playGame()">
          <div class="btn-animation">Play</div>
        </b-button>

        <b-container fluid="md" class="mt-3 px-0" style="max-width: 900px;">
          <div v-if="gameData.desc" class="text-collapse-wrapper" style="color: rgb(210 210 210); font-weight:500;">
            <input id="exp1" class="exp" type="checkbox">
            <div class="text">
              <label class="btn" for="exp1"></label>
              {{ gameData.desc }}
            </div>
          </div>
        </b-container>

      </b-card-body>
    </b-card>
    <div class="mt-3">OTHER GAMES</div>
    <GameList ref="gameList" v-if="gameData.id" :excludeGameId="gameData.id"></GameList>
  </div>
</template>

<script>
// import { BIconHandThumbsUpFill } from 'bootstrap-vue';
import gameApi from '../api/gameApi.js';
import GameList from '../components/GameList.vue';

export default {
  name: "GameDetailView",
  components: {
    GameList
  },
  created() { },
  data() {
    return {
      gameid: 0,
      gameData: {},
      gamePlayData: {},
      dataLoaded: false,
      names: {
                "Zombies Cant Jump 2": {
                    name: "Undead Leap Challenge 2",
                    desc: "Undead Leap Challenge 2 is a thrilling sequel that pits the player against hordes of the undead. With new obstacles and challenges, players must outmaneuver the zombies who can't jump, using strategy and quick reflexes to survive.",
                },
                "Zombies Cant Jump 1": {
                    name: "Ghouls Can't Leap 1",
                    desc: "Ghouls Can't Leap 1 introduces players to a world where ghouls are afoot, unable to leap over obstacles. Master the art of evasion in this strategic game that tests your agility and cunning.",
                },
                "Yeti Sensation": {
                    name: "Abominable Adventure",
                    desc: "Embark on the Abominable Adventure, a journey through the snowy mountains where you play as the mighty yeti. Experience the sensation of being a legendary creature, overcoming challenges and discovering the secrets of the mountain.",
                },
                "The Maze": {
                    name: "Labyrinth Labyrinth",
                    desc: "Labyrinth Labyrinth is a puzzle game that challenges players to navigate through a complex maze filled with traps and dead ends. Use your wits to find the exit and escape the ever-changing labyrinth.",
                },
                "Super Neon Ball": {
                    name: "Ultra Luminous Orb",
                    desc: "Ultra Luminous Orb is an action-packed game where you control a glowing orb, bouncing and reflecting through neon-lit levels. Master the art of light manipulation to navigate through the vibrant and challenging courses.",
                },
                "Super Knight": {
                    name: "Mega Warrior",
                    desc: "Mega Warrior is an epic adventure game where you play as a powerful knight, battling through hordes of enemies to save the kingdom. With enhanced abilities and weaponry, this warrior is ready for the ultimate showdown.",
                },
                "Stickman Vex 4": {
                    name: "Puzzleman Mayhem 4",
                    desc: "Puzzleman Mayhem 4 is the latest installment in the Stickman Vex series, offering more challenging puzzles and intense action. Navigate the stickman through a world of chaos and confusion to reach the end of each level.",
                },
                "Stephen Karsch": {
                    name: "Steven Quest",
                    desc: "Steven Quest follows the adventures of a brave explorer, delving into ancient ruins and solving intricate puzzles. With each step, uncover the mysteries of the past and the secrets that lie within.",
                },
                Shmupnage: {
                    name: "Shooter Frenzy",
                    desc: "Shooter Frenzy is an adrenaline-pumping shoot 'em up game where you pilot a spacecraft through waves of enemies. Upgrade your arsenal and dodge relentless attacks in this frenzied space battle.",
                },
                "Rooster Warrior": {
                    name: "Cock-a-Doodle Defender",
                    desc: "Cock-a-Doodle Defender is a unique defense game where you play as a rooster guardian. Protect your coop from invading forces using a variety of weapons and strategies in this clucking good time.",
                },
                "Rally Racer": {
                    name: "Racing Rally",
                    desc: "Racing Rally is an intense rally racing game that puts you behind the wheel of powerful off-road vehicles. Race through diverse terrains, mastering each track to become the ultimate rally champion.",
                },
                "Pinnacle Racer": {
                    name: "Peak Pursuit",
                    desc: "Peak Pursuit takes you on a high-speed chase to the summit. Race against opponents and the clock, conquering treacherous mountain roads to reach the peak first in this thrilling racing experience.",
                },
                "Owl House": {
                    name: "Hoot Home",
                    desc: "Hoot Home is a charming puzzle game where you help an owl build its dream home. Gather materials, solve puzzles, and create the perfect abode in this cozy and enchanting adventure.",
                },
                "Nin Jago Braw": {
                    name: "Ninja Joust",
                    desc: "Ninja Joust is a competitive multiplayer game where players engage in intense ninja battles. Use stealth, speed, and strategy to outwit your opponents and claim victory in the arena.",
                },
                "Ninja Boy 2": {
                    name: "Shadow Warrior 2",
                    desc: "Shadow Warrior 2 continues the saga of the ninja boy, with new skills and challenges. Traverse the shadows, master the art of combat, and uncover the mysteries of the ancient ninja world.",
                },
                "Ninja Boy 1": {
                    name: "Sneaky Samurai 1",
                    desc: "Sneaky Samurai 1 is a stealth action game where you play as a samurai on a mission. Use your agility and cunning to sneak past enemies, or strike with deadly precision when the time is right.",
                },
                "Mystery Chase": {
                    name: "Enigma Pursuit",
                    desc: "Enigma Pursuit is a thrilling detective game filled with mystery and intrigue. Follow the clues, interrogate suspects, and solve the puzzles to uncover the truth behind a series of enigmatic crimes.",
                },
                "Knight Rider": {
                    name: "Chivalric Charger",
                    desc: "Chivalric Charger is a medieval action game where you play as a knight on a noble quest. Charge into battle, defend the realm, and uphold the code of chivalry in this epic journey.",
                },
                "King Soldier": {
                    name: "Sovereign Sentinel",
                    desc: "Sovereign Sentinel is a strategy game where you command an army to protect the kingdom. Deploy soldiers, construct defenses, and outsmart your enemies to ensure the safety of the realm.",
                },
                "Keep Out": {
                    name: "Stay Away",
                    desc: "Stay Away is a unique game that challenges you to keep intruders at bay. Set traps, build barriers, and use your wits to keep the unwanted visitors from crossing your territory.",
                },
                Gunmach: {
                    name: "Blaster Bot",
                    desc: "Blaster Bot is an action-packed robot combat game. Customize your robot, unleash powerful weapons, and battle against other bots in this high-octane arena showdown.",
                },
                "Epic War 2": {
                    name: "Legendary Battles 2",
                    desc: "Legendary Battles 2 is a grand strategy game that brings you to the forefront of epic warfare. Lead your army, forge alliances, and engage in legendary battles to determine the fate of the realm.",
                },
                "Epic War 1": {
                    name: "Heroic Conflicts 1",
                    desc: "Heroic Conflicts 1 is a tactical war game that puts you in the midst of heroic battles. Command your troops wisely, plan your strategies, and lead your forces to victory in this conflict of heroes.",
                },
                "Drift Rally Champion": {
                    name: "Slide Sprint Supreme",
                    desc: "Slide Sprint Supreme is a racing game that celebrates the art of drifting. Master the slide on various tracks, and become the ultimate drift champion in this high-speed competition.",
                },
                "Diseviled Stolen Kingdom": {
                    name: "Cursed Kingdom Conquest",
                    desc: "Cursed Kingdom Conquest is a fantasy adventure game where you quest to reclaim a stolen kingdom. Battle evil forces, solve ancient riddles, and restore the kingdom to its former glory.",
                },
                "Dino Rage": {
                    name: "Jurassic Fury",
                    desc: "Jurassic Fury brings you back to the age of dinosaurs. Experience the raw power and survival instincts of these prehistoric creatures in a game filled with action and adventure.",
                },
                "Defend The Sand Castle": {
                    name: "Protect the Dune Fortress",
                    desc: "Protect the Dune Fortress is a strategic defense game where you must defend your castle from waves of invaders. Build defenses, strategize, and hold the line against the relentless assault.",
                },
                "Cyber Champions Arena": {
                    name: "Digital Duelists Coliseum",
                    desc: "Digital Duelists Coliseum is a futuristic fighting game set in a virtual arena. Choose your cyber warrior, master their abilities, and battle against opponents in this high-tech coliseum.",
                },
                "Cold Station": {
                    name: "Frost Depot",
                    desc: "Frost Depot is a survival game set in a frozen wasteland. Gather resources, build shelter, and endure the harsh conditions of the cold in this challenging and immersive experience.",
                },
                "Black Panther Jungle Pursuit": {
                    name: "Panther Chase in the Wild",
                    desc: "Panther Chase in the Wild is an action-adventure game where you track and document the elusive black panther in its natural habitat. Experience the thrill of the chase and the beauty of the wild.",
                },
                "Ben 10 World Rescue": {
                    name: "Alien Adventurer's Aid",
                    desc: "Alien Adventurer's Aid follows Ben Tennyson as he uses the Omnitrix to transform into various aliens and save the world from threats. Experience the adventure of an alien hero in this action-packed game.",
                },
                "Battle Robot Wolf Age": {
                    name: "Combat Mech Wolf Epoch",
                    desc: "Combat Mech Wolf Epoch is a mech combat game set in a futuristic world. Pilot a wolf-like mech, engage in fierce battles, and dominate the battlefield with your tactical prowess.",
                },
                "Battle Robot TRex Age": {
                    name: "Dino Mech Tyrant Era",
                    desc: "Dino Mech Tyrant Era brings the power of the T-Rex to the world of mechs. Control a dinosaur-inspired mech and battle for supremacy in this prehistoric-themed mech combat game.",
                },
                "Battle Robot Samurai Age": {
                    name: "Sword Bot Shogun Epoch",
                    desc: "Sword Bot Shogun Epoch is a fusion of samurai and mech warfare. Wield a sword in your mech and engage in honorable combat, reflecting the spirit of the samurai.",
                },
                "Archery Training": {
                    name: "Bowmanship Bootcamp",
                    desc: "Bowmanship Bootcamp is a training simulation that helps you master the art of archery. Improve your accuracy, power, and technique through various drills and challenges.",
                },
                "13 Nights": {
                    name: "Thirteen Twilights",
                    desc: "Thirteen Twilights is a horror-adventure game where you must survive through thirteen eerie nights. Face supernatural threats, solve puzzles, and uncover the truth behind the haunting events.",
                },
            },
    };
  },
  watch: {
    $route(to) {
      this.gameid = to.params.gameid;
      this.getGameDetail();
      this.$refs.gameList.refresh();
    },
  },
  mounted() {
    console.log('GameDetailView mounted');
    this.gameid = this.$route.params.gameid;
    this.getGameDetail();
  },
  methods: {
    getName(name) {
      if(this.names[name]) {
        return this.names[name].name
      } else {
        return name
      }
    },
    getDesc(name) {
      if(this.names[name]) {
        return this.names[name].desc
      } else {
        return ""
      }
    },
    getGameDetail() {
      gameApi.getGameInfo(this.gameid).then((response) => {
        console.log('response', response);
        if (response.status === 200 && response.data.code == 0) {
          this.gameData = response.data.data;
          this.gameData.desc = this.getDesc(response.data.data.name);
          this.gameData.name = this.getName(response.data.data.name);
          this.dataLoaded = true;
          console.log('gameData: ', this.gameData);
        } else {
          console.log('request gameApi.getGameDetail error', response);
        }
      });
    },
    playGame() {
      console.log('playGame');
      this.$router.push({ name: 'play', params: { gameid: this.gameid, gamename: this.getGameNameWithDash(this.gameData.name) } });
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
    getGameNameWithDash(str) {
      // convert string with white space into array
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1-$2').split(' ').join('-').toLowerCase();
    },
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../app.scss";

.btn-primary {
  color: #ffffff;
  font-size: x-large;
  font-weight: 500;
  background-color: $primary-color;
  // border-color: #c80000;
}

.btn-animation {
  transform-origin: center;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}

.text-collapse-wrapper {
  display: flex;
  // margin: 50px auto;
  max-width: 900px;
  overflow: hidden;
  /*   resize: horizontal; */
  // border-radius: 8px;
  // padding: 15px;
  // box-shadow: 20px 20px 60px #bebebe,
  //   -20px -20px 60px #ffffff;
}

.text-collapse-wrapper .text {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  /* display: flex; */
  /*   display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  position: relative;
  line-height: 1.3;
  max-height: 2.5em;
  transition: .3s max-height;
}

.text-collapse-wrapper .text::before {
  content: '';
  height: calc(100% - 16px);
  float: right;
}

.text-collapse-wrapper .text::after {
  content: '';
  width: 999vw;
  height: 999vw;
  position: absolute;
  box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #fff;
  margin-left: -100px;
}

.text-collapse-wrapper .btn {
  position: relative;
  float: right;
  clear: both;
  margin-left: 12px;
  font-size: 12px;
  padding: 0 8px;
  background: #0065BD;
  line-height: 12px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  /* margin-top: -30px; */
}

.text-collapse-wrapper .btn::after {
  content: 'expand'
}

.text-collapse-wrapper .exp {
  display: none;
}

.text-collapse-wrapper .exp:checked+.text {
  max-height: none;
}

.text-collapse-wrapper .exp:checked+.text::after {
  visibility: hidden;
}

.text-collapse-wrapper .exp:checked+.text .btn::before {
  visibility: hidden;
}

.text-collapse-wrapper .exp:checked+.text .btn::after {
  content: 'hide'
}

.text-collapse-wrapper .btn::before {
  content: '...';
  position: absolute;
  left: -5px;
  color: #333;
  transform: translateX(-100%)
}
</style>